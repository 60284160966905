import React from "react";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { GraphiQL } from "graphiql";
import { Spinner } from "@jobber/components/Spinner";
import { useJobberAccessToken } from "./hooks/useJobberAccessToken";
import * as styles from "./TestApplicationPlayground.module.css";
import "graphiql/graphiql.css";
import "./customGraphiql.css";

export function TestApplicationPlayground() {
  const { accessToken } = useJobberAccessToken();
  const jobberGraphQLUrl = process.env.GATSBY_JOBBER_MAIN_API_URL || "";

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "X-JOBBER-GRAPHQL-VERSION": "2024-06-10",
  };

  const fetcher = createGraphiQLFetcher({
    url: jobberGraphQLUrl,
    headers: headers,
  });

  const prettifiedHeaderEditorContent = JSON.stringify(
    JSON.parse(JSON.stringify(headers)),
    undefined,
    2,
  );

  return (
    <div className={styles.graphiql}>
      {accessToken ? (
        <GraphiQL
          fetcher={fetcher}
          defaultTabs={[
            { query: "query SampleQuery { clients { totalCount } }" },
          ]}
          isHeadersEditorEnabled={true}
          defaultHeaders={prettifiedHeaderEditorContent}
        >
          <GraphiQL.Logo>
            <>
              Graph
              <em>i</em>
              QL
            </>
          </GraphiQL.Logo>
        </GraphiQL>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
